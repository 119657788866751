<template>
    <div>
        <ConfirmDialog></ConfirmDialog>
        <Toast />
        <div class="card">
            <Button class="p-button-outlined p-button-secondary" type="button" label="Вернуться в корзину" icon="pi pi-angle-left" @click="prevPage()"/>
            <h5>Форма заказа</h5>
            <div class="p-fluid p-formgrid p-grid">
                <div class="p-field p-col-12 p-md-6">
                    <label for="name">Фамилия Имя Отчество</label>
                    <InputText v-model="orderService.order.customer.name" id="name" type="text" />
                </div>
                <div class="p-field p-col-12 p-md-6">
                    <label for="phone">Номер телефона</label>
                    <InputMask mask="(999) 999-9999" v-model="orderService.order.customer.phone" id="phone" placeholder="(999) 999-9999" />
                </div>
                <div class="p-field p-col-12 p-md-12">
                    <label for="city">Адрес доставки</label>
                    <InputText v-model="orderService.order.customer.address" id="adress" type="text" />
                </div>
                <div class="p-field p-col-12">
                    <p>Количество товаров: {{orderService.order.total.quantity}}</p>
                    <p>Объем заказа: {{orderService.order.total.weight}} л.</p>
                    <p><b>Сумма заказа: {{orderService.order.total.sum}} грн.</b></p>
                    <router-link to="/complete">
                    <Button class="p-button-secondary" type="button" label="Отправить заказ"  @click="setOrder"/>
                    </router-link>
                    <router-view></router-view>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import OrderService from '../service/OrderService';

export default {
    data() {
        return {
        }
    },
    orderService: null,
    created() {
		this.orderService = new OrderService();
        this.orderService.order = JSON.parse(JSON.stringify(this.globalOrder));
    },
    computed: mapState({
        globalOrder: state => state.orders.globalOrder
    }),

    methods: {
        ...mapMutations([
            'setOrder',
            'setCartCount'
        ]),
        prevPage() {
            this.$emit('prev-page', {pageIndex: 1});
        },

        async setOrder () {
            await this.orderService.saveOrder(this.orderService.order);
            await this.setOrder(this.orderService.order);
            
        },

        
    }
}
</script>

<style scoped>
.card {
    max-width: 95%;
}
.qua-container {
    display: flex;
}
.qua-item {
    margin: 10px;
}
.footer {
    padding: 10px 0px;
}
.footer-item {
    text-align: center;
}
</style>